import { pushScopeId as qt, popScopeId as Gt, defineComponent as xe, nextTick as Yt, ref as ke, openBlock as I, createBlock as be, createApp as Xt, h as Ut, createElementBlock as ne, normalizeClass as Fe, renderSlot as me, normalizeProps as Kt, guardReactiveProps as Zt, withScopeId as Qt, resolveComponent as se, normalizeStyle as ze, withKeys as Jt, createElementVNode as K, Fragment as eo, createCommentVNode as Ke, mergeProps as wt, withCtx as ge, createVNode as vt, toDisplayString as to } from "vue";
const oo = ["top", "right", "bottom", "left"], Ze = ["start", "end"], Qe = /* @__PURE__ */ oo.reduce((e, t) => e.concat(t, t + "-" + Ze[0], t + "-" + Ze[1]), []), re = Math.min, U = Math.max, io = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, no = {
  start: "end",
  end: "start"
};
function Me(e, t, o) {
  return U(e, re(t, o));
}
function Z(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function D(e) {
  return e.split("-")[0];
}
function L(e) {
  return e.split("-")[1];
}
function yt(e) {
  return e === "x" ? "y" : "x";
}
function Ie(e) {
  return e === "y" ? "height" : "width";
}
function ce(e) {
  return ["top", "bottom"].includes(D(e)) ? "y" : "x";
}
function je(e) {
  return yt(ce(e));
}
function $t(e, t, o) {
  o === void 0 && (o = !1);
  const i = L(e), n = je(e), s = Ie(n);
  let r = n === "x" ? i === (o ? "end" : "start") ? "right" : "left" : i === "start" ? "bottom" : "top";
  return t.reference[s] > t.floating[s] && (r = ve(r)), [r, ve(r)];
}
function so(e) {
  const t = ve(e);
  return [we(e), t, we(t)];
}
function we(e) {
  return e.replace(/start|end/g, (t) => no[t]);
}
function ro(e, t, o) {
  const i = ["left", "right"], n = ["right", "left"], s = ["top", "bottom"], r = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return o ? t ? n : i : t ? i : n;
    case "left":
    case "right":
      return t ? s : r;
    default:
      return [];
  }
}
function ao(e, t, o, i) {
  const n = L(e);
  let s = ro(D(e), o === "start", i);
  return n && (s = s.map((r) => r + "-" + n), t && (s = s.concat(s.map(we)))), s;
}
function ve(e) {
  return e.replace(/left|right|bottom|top/g, (t) => io[t]);
}
function lo(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function _t(e) {
  return typeof e != "number" ? lo(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function ee(e) {
  return {
    ...e,
    top: e.y,
    left: e.x,
    right: e.x + e.width,
    bottom: e.y + e.height
  };
}
function Je(e, t, o) {
  let {
    reference: i,
    floating: n
  } = e;
  const s = ce(t), r = je(t), a = Ie(r), l = D(t), d = s === "y", f = i.x + i.width / 2 - n.width / 2, c = i.y + i.height / 2 - n.height / 2, h = i[a] / 2 - n[a] / 2;
  let p;
  switch (l) {
    case "top":
      p = {
        x: f,
        y: i.y - n.height
      };
      break;
    case "bottom":
      p = {
        x: f,
        y: i.y + i.height
      };
      break;
    case "right":
      p = {
        x: i.x + i.width,
        y: c
      };
      break;
    case "left":
      p = {
        x: i.x - n.width,
        y: c
      };
      break;
    default:
      p = {
        x: i.x,
        y: i.y
      };
  }
  switch (L(t)) {
    case "start":
      p[r] -= h * (o && d ? -1 : 1);
      break;
    case "end":
      p[r] += h * (o && d ? -1 : 1);
      break;
  }
  return p;
}
const po = async (e, t, o) => {
  const {
    placement: i = "bottom",
    strategy: n = "absolute",
    middleware: s = [],
    platform: r
  } = o, a = s.filter(Boolean), l = await (r.isRTL == null ? void 0 : r.isRTL(t));
  let d = await r.getElementRects({
    reference: e,
    floating: t,
    strategy: n
  }), {
    x: f,
    y: c
  } = Je(d, i, l), h = i, p = {}, u = 0;
  for (let m = 0; m < a.length; m++) {
    const {
      name: w,
      fn: g
    } = a[m], {
      x: v,
      y: $,
      data: x,
      reset: _
    } = await g({
      x: f,
      y: c,
      initialPlacement: i,
      placement: h,
      strategy: n,
      middlewareData: p,
      rects: d,
      platform: r,
      elements: {
        reference: e,
        floating: t
      }
    });
    if (f = v ?? f, c = $ ?? c, p = {
      ...p,
      [w]: {
        ...p[w],
        ...x
      }
    }, _ && u <= 50) {
      u++, typeof _ == "object" && (_.placement && (h = _.placement), _.rects && (d = _.rects === !0 ? await r.getElementRects({
        reference: e,
        floating: t,
        strategy: n
      }) : _.rects), {
        x: f,
        y: c
      } = Je(d, h, l)), m = -1;
      continue;
    }
  }
  return {
    x: f,
    y: c,
    placement: h,
    strategy: n,
    middlewareData: p
  };
};
async function Te(e, t) {
  var o;
  t === void 0 && (t = {});
  const {
    x: i,
    y: n,
    platform: s,
    rects: r,
    elements: a,
    strategy: l
  } = e, {
    boundary: d = "clippingAncestors",
    rootBoundary: f = "viewport",
    elementContext: c = "floating",
    altBoundary: h = !1,
    padding: p = 0
  } = Z(t, e), u = _t(p), w = a[h ? c === "floating" ? "reference" : "floating" : c], g = ee(await s.getClippingRect({
    element: (o = await (s.isElement == null ? void 0 : s.isElement(w))) == null || o ? w : w.contextElement || await (s.getDocumentElement == null ? void 0 : s.getDocumentElement(a.floating)),
    boundary: d,
    rootBoundary: f,
    strategy: l
  })), v = c === "floating" ? {
    ...r.floating,
    x: i,
    y: n
  } : r.reference, $ = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(a.floating)), x = await (s.isElement == null ? void 0 : s.isElement($)) ? await (s.getScale == null ? void 0 : s.getScale($)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, _ = ee(s.convertOffsetParentRelativeRectToViewportRelativeRect ? await s.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: v,
    offsetParent: $,
    strategy: l
  }) : v);
  return {
    top: (g.top - _.top + u.top) / x.y,
    bottom: (_.bottom - g.bottom + u.bottom) / x.y,
    left: (g.left - _.left + u.left) / x.x,
    right: (_.right - g.right + u.right) / x.x
  };
}
const co = (e) => ({
  name: "arrow",
  options: e,
  async fn(t) {
    const {
      x: o,
      y: i,
      placement: n,
      rects: s,
      platform: r,
      elements: a,
      middlewareData: l
    } = t, {
      element: d,
      padding: f = 0
    } = Z(e, t) || {};
    if (d == null)
      return {};
    const c = _t(f), h = {
      x: o,
      y: i
    }, p = je(n), u = Ie(p), m = await r.getDimensions(d), w = p === "y", g = w ? "top" : "left", v = w ? "bottom" : "right", $ = w ? "clientHeight" : "clientWidth", x = s.reference[u] + s.reference[p] - h[p] - s.floating[u], _ = h[p] - s.reference[p], b = await (r.getOffsetParent == null ? void 0 : r.getOffsetParent(d));
    let T = b ? b[$] : 0;
    (!T || !await (r.isElement == null ? void 0 : r.isElement(b))) && (T = a.floating[$] || s.floating[u]);
    const z = x / 2 - _ / 2, N = T / 2 - m[u] / 2 - 1, S = re(c[g], N), H = re(c[v], N), P = S, Y = T - m[u] - H, C = T / 2 - m[u] / 2 + z, M = Me(P, C, Y), R = !l.arrow && L(n) != null && C != M && s.reference[u] / 2 - (C < P ? S : H) - m[u] / 2 < 0, F = R ? C < P ? C - P : C - Y : 0;
    return {
      [p]: h[p] + F,
      data: {
        [p]: M,
        centerOffset: C - M - F,
        ...R && {
          alignmentOffset: F
        }
      },
      reset: R
    };
  }
});
function ho(e, t, o) {
  return (e ? [...o.filter((n) => L(n) === e), ...o.filter((n) => L(n) !== e)] : o.filter((n) => D(n) === n)).filter((n) => e ? L(n) === e || (t ? we(n) !== n : !1) : !0);
}
const uo = function(e) {
  return e === void 0 && (e = {}), {
    name: "autoPlacement",
    options: e,
    async fn(t) {
      var o, i, n;
      const {
        rects: s,
        middlewareData: r,
        placement: a,
        platform: l,
        elements: d
      } = t, {
        crossAxis: f = !1,
        alignment: c,
        allowedPlacements: h = Qe,
        autoAlignment: p = !0,
        ...u
      } = Z(e, t), m = c !== void 0 || h === Qe ? ho(c || null, p, h) : h, w = await Te(t, u), g = ((o = r.autoPlacement) == null ? void 0 : o.index) || 0, v = m[g];
      if (v == null)
        return {};
      const $ = $t(v, s, await (l.isRTL == null ? void 0 : l.isRTL(d.floating)));
      if (a !== v)
        return {
          reset: {
            placement: m[0]
          }
        };
      const x = [w[D(v)], w[$[0]], w[$[1]]], _ = [...((i = r.autoPlacement) == null ? void 0 : i.overflows) || [], {
        placement: v,
        overflows: x
      }], b = m[g + 1];
      if (b)
        return {
          data: {
            index: g + 1,
            overflows: _
          },
          reset: {
            placement: b
          }
        };
      const T = _.map((S) => {
        const H = L(S.placement);
        return [S.placement, H && f ? (
          // Check along the mainAxis and main crossAxis side.
          S.overflows.slice(0, 2).reduce((P, Y) => P + Y, 0)
        ) : (
          // Check only the mainAxis.
          S.overflows[0]
        ), S.overflows];
      }).sort((S, H) => S[1] - H[1]), N = ((n = T.filter((S) => S[2].slice(
        0,
        // Aligned placements should not check their opposite crossAxis
        // side.
        L(S[0]) ? 2 : 3
      ).every((H) => H <= 0))[0]) == null ? void 0 : n[0]) || T[0][0];
      return N !== a ? {
        data: {
          index: g + 1,
          overflows: _
        },
        reset: {
          placement: N
        }
      } : {};
    }
  };
}, fo = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var o, i;
      const {
        placement: n,
        middlewareData: s,
        rects: r,
        initialPlacement: a,
        platform: l,
        elements: d
      } = t, {
        mainAxis: f = !0,
        crossAxis: c = !0,
        fallbackPlacements: h,
        fallbackStrategy: p = "bestFit",
        fallbackAxisSideDirection: u = "none",
        flipAlignment: m = !0,
        ...w
      } = Z(e, t);
      if ((o = s.arrow) != null && o.alignmentOffset)
        return {};
      const g = D(n), v = D(a) === a, $ = await (l.isRTL == null ? void 0 : l.isRTL(d.floating)), x = h || (v || !m ? [ve(a)] : so(a));
      !h && u !== "none" && x.push(...ao(a, m, u, $));
      const _ = [a, ...x], b = await Te(t, w), T = [];
      let z = ((i = s.flip) == null ? void 0 : i.overflows) || [];
      if (f && T.push(b[g]), c) {
        const P = $t(n, r, $);
        T.push(b[P[0]], b[P[1]]);
      }
      if (z = [...z, {
        placement: n,
        overflows: T
      }], !T.every((P) => P <= 0)) {
        var N, S;
        const P = (((N = s.flip) == null ? void 0 : N.index) || 0) + 1, Y = _[P];
        if (Y)
          return {
            data: {
              index: P,
              overflows: z
            },
            reset: {
              placement: Y
            }
          };
        let C = (S = z.filter((M) => M.overflows[0] <= 0).sort((M, R) => M.overflows[1] - R.overflows[1])[0]) == null ? void 0 : S.placement;
        if (!C)
          switch (p) {
            case "bestFit": {
              var H;
              const M = (H = z.map((R) => [R.placement, R.overflows.filter((F) => F > 0).reduce((F, Vt) => F + Vt, 0)]).sort((R, F) => R[1] - F[1])[0]) == null ? void 0 : H[0];
              M && (C = M);
              break;
            }
            case "initialPlacement":
              C = a;
              break;
          }
        if (n !== C)
          return {
            reset: {
              placement: C
            }
          };
      }
      return {};
    }
  };
};
async function mo(e, t) {
  const {
    placement: o,
    platform: i,
    elements: n
  } = e, s = await (i.isRTL == null ? void 0 : i.isRTL(n.floating)), r = D(o), a = L(o), l = ce(o) === "y", d = ["left", "top"].includes(r) ? -1 : 1, f = s && l ? -1 : 1, c = Z(t, e);
  let {
    mainAxis: h,
    crossAxis: p,
    alignmentAxis: u
  } = typeof c == "number" ? {
    mainAxis: c,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...c
  };
  return a && typeof u == "number" && (p = a === "end" ? u * -1 : u), l ? {
    x: p * f,
    y: h * d
  } : {
    x: h * d,
    y: p * f
  };
}
const go = function(e) {
  return e === void 0 && (e = 0), {
    name: "offset",
    options: e,
    async fn(t) {
      var o, i;
      const {
        x: n,
        y: s,
        placement: r,
        middlewareData: a
      } = t, l = await mo(t, e);
      return r === ((o = a.offset) == null ? void 0 : o.placement) && (i = a.arrow) != null && i.alignmentOffset ? {} : {
        x: n + l.x,
        y: s + l.y,
        data: {
          ...l,
          placement: r
        }
      };
    }
  };
}, wo = function(e) {
  return e === void 0 && (e = {}), {
    name: "shift",
    options: e,
    async fn(t) {
      const {
        x: o,
        y: i,
        placement: n
      } = t, {
        mainAxis: s = !0,
        crossAxis: r = !1,
        limiter: a = {
          fn: (w) => {
            let {
              x: g,
              y: v
            } = w;
            return {
              x: g,
              y: v
            };
          }
        },
        ...l
      } = Z(e, t), d = {
        x: o,
        y: i
      }, f = await Te(t, l), c = ce(D(n)), h = yt(c);
      let p = d[h], u = d[c];
      if (s) {
        const w = h === "y" ? "top" : "left", g = h === "y" ? "bottom" : "right", v = p + f[w], $ = p - f[g];
        p = Me(v, p, $);
      }
      if (r) {
        const w = c === "y" ? "top" : "left", g = c === "y" ? "bottom" : "right", v = u + f[w], $ = u - f[g];
        u = Me(v, u, $);
      }
      const m = a.fn({
        ...t,
        [h]: p,
        [c]: u
      });
      return {
        ...m,
        data: {
          x: m.x - o,
          y: m.y - i
        }
      };
    }
  };
}, vo = function(e) {
  return e === void 0 && (e = {}), {
    name: "size",
    options: e,
    async fn(t) {
      const {
        placement: o,
        rects: i,
        platform: n,
        elements: s
      } = t, {
        apply: r = () => {
        },
        ...a
      } = Z(e, t), l = await Te(t, a), d = D(o), f = L(o), c = ce(o) === "y", {
        width: h,
        height: p
      } = i.floating;
      let u, m;
      d === "top" || d === "bottom" ? (u = d, m = f === (await (n.isRTL == null ? void 0 : n.isRTL(s.floating)) ? "start" : "end") ? "left" : "right") : (m = d, u = f === "end" ? "top" : "bottom");
      const w = p - l[u], g = h - l[m], v = !t.middlewareData.shift;
      let $ = w, x = g;
      if (c) {
        const b = h - l.left - l.right;
        x = f || v ? re(g, b) : b;
      } else {
        const b = p - l.top - l.bottom;
        $ = f || v ? re(w, b) : b;
      }
      if (v && !f) {
        const b = U(l.left, 0), T = U(l.right, 0), z = U(l.top, 0), N = U(l.bottom, 0);
        c ? x = h - 2 * (b !== 0 || T !== 0 ? b + T : U(l.left, l.right)) : $ = p - 2 * (z !== 0 || N !== 0 ? z + N : U(l.top, l.bottom));
      }
      await r({
        ...t,
        availableWidth: x,
        availableHeight: $
      });
      const _ = await n.getDimensions(s.floating);
      return h !== _.width || p !== _.height ? {
        reset: {
          rects: !0
        }
      } : {};
    }
  };
};
function O(e) {
  var t;
  return ((t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function B(e) {
  return O(e).getComputedStyle(e);
}
const et = Math.min, te = Math.max, ye = Math.round;
function xt(e) {
  const t = B(e);
  let o = parseFloat(t.width), i = parseFloat(t.height);
  const n = e.offsetWidth, s = e.offsetHeight, r = ye(o) !== n || ye(i) !== s;
  return r && (o = n, i = s), { width: o, height: i, fallback: r };
}
function G(e) {
  return Tt(e) ? (e.nodeName || "").toLowerCase() : "";
}
let he;
function bt() {
  if (he)
    return he;
  const e = navigator.userAgentData;
  return e && Array.isArray(e.brands) ? (he = e.brands.map((t) => t.brand + "/" + t.version).join(" "), he) : navigator.userAgent;
}
function E(e) {
  return e instanceof O(e).HTMLElement;
}
function V(e) {
  return e instanceof O(e).Element;
}
function Tt(e) {
  return e instanceof O(e).Node;
}
function tt(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof O(e).ShadowRoot || e instanceof ShadowRoot;
}
function Se(e) {
  const { overflow: t, overflowX: o, overflowY: i, display: n } = B(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + i + o) && !["inline", "contents"].includes(n);
}
function yo(e) {
  return ["table", "td", "th"].includes(G(e));
}
function Re(e) {
  const t = /firefox/i.test(bt()), o = B(e), i = o.backdropFilter || o.WebkitBackdropFilter;
  return o.transform !== "none" || o.perspective !== "none" || !!i && i !== "none" || t && o.willChange === "filter" || t && !!o.filter && o.filter !== "none" || ["transform", "perspective"].some((n) => o.willChange.includes(n)) || ["paint", "layout", "strict", "content"].some((n) => {
    const s = o.contain;
    return s != null && s.includes(n);
  });
}
function St() {
  return !/^((?!chrome|android).)*safari/i.test(bt());
}
function We(e) {
  return ["html", "body", "#document"].includes(G(e));
}
function Pt(e) {
  return V(e) ? e : e.contextElement;
}
const Ct = { x: 1, y: 1 };
function Q(e) {
  const t = Pt(e);
  if (!E(t))
    return Ct;
  const o = t.getBoundingClientRect(), { width: i, height: n, fallback: s } = xt(t);
  let r = (s ? ye(o.width) : o.width) / i, a = (s ? ye(o.height) : o.height) / n;
  return r && Number.isFinite(r) || (r = 1), a && Number.isFinite(a) || (a = 1), { x: r, y: a };
}
function ae(e, t, o, i) {
  var n, s;
  t === void 0 && (t = !1), o === void 0 && (o = !1);
  const r = e.getBoundingClientRect(), a = Pt(e);
  let l = Ct;
  t && (i ? V(i) && (l = Q(i)) : l = Q(e));
  const d = a ? O(a) : window, f = !St() && o;
  let c = (r.left + (f && ((n = d.visualViewport) == null ? void 0 : n.offsetLeft) || 0)) / l.x, h = (r.top + (f && ((s = d.visualViewport) == null ? void 0 : s.offsetTop) || 0)) / l.y, p = r.width / l.x, u = r.height / l.y;
  if (a) {
    const m = O(a), w = i && V(i) ? O(i) : i;
    let g = m.frameElement;
    for (; g && i && w !== m; ) {
      const v = Q(g), $ = g.getBoundingClientRect(), x = getComputedStyle(g);
      $.x += (g.clientLeft + parseFloat(x.paddingLeft)) * v.x, $.y += (g.clientTop + parseFloat(x.paddingTop)) * v.y, c *= v.x, h *= v.y, p *= v.x, u *= v.y, c += $.x, h += $.y, g = O(g).frameElement;
    }
  }
  return { width: p, height: u, top: h, right: c + p, bottom: h + u, left: c, x: c, y: h };
}
function q(e) {
  return ((Tt(e) ? e.ownerDocument : e.document) || window.document).documentElement;
}
function Pe(e) {
  return V(e) ? { scrollLeft: e.scrollLeft, scrollTop: e.scrollTop } : { scrollLeft: e.pageXOffset, scrollTop: e.pageYOffset };
}
function At(e) {
  return ae(q(e)).left + Pe(e).scrollLeft;
}
function le(e) {
  if (G(e) === "html")
    return e;
  const t = e.assignedSlot || e.parentNode || tt(e) && e.host || q(e);
  return tt(t) ? t.host : t;
}
function Ot(e) {
  const t = le(e);
  return We(t) ? t.ownerDocument.body : E(t) && Se(t) ? t : Ot(t);
}
function $e(e, t) {
  var o;
  t === void 0 && (t = []);
  const i = Ot(e), n = i === ((o = e.ownerDocument) == null ? void 0 : o.body), s = O(i);
  return n ? t.concat(s, s.visualViewport || [], Se(i) ? i : []) : t.concat(i, $e(i));
}
function ot(e, t, o) {
  return t === "viewport" ? ee(function(i, n) {
    const s = O(i), r = q(i), a = s.visualViewport;
    let l = r.clientWidth, d = r.clientHeight, f = 0, c = 0;
    if (a) {
      l = a.width, d = a.height;
      const h = St();
      (h || !h && n === "fixed") && (f = a.offsetLeft, c = a.offsetTop);
    }
    return { width: l, height: d, x: f, y: c };
  }(e, o)) : V(t) ? ee(function(i, n) {
    const s = ae(i, !0, n === "fixed"), r = s.top + i.clientTop, a = s.left + i.clientLeft, l = E(i) ? Q(i) : { x: 1, y: 1 };
    return { width: i.clientWidth * l.x, height: i.clientHeight * l.y, x: a * l.x, y: r * l.y };
  }(t, o)) : ee(function(i) {
    const n = q(i), s = Pe(i), r = i.ownerDocument.body, a = te(n.scrollWidth, n.clientWidth, r.scrollWidth, r.clientWidth), l = te(n.scrollHeight, n.clientHeight, r.scrollHeight, r.clientHeight);
    let d = -s.scrollLeft + At(i);
    const f = -s.scrollTop;
    return B(r).direction === "rtl" && (d += te(n.clientWidth, r.clientWidth) - a), { width: a, height: l, x: d, y: f };
  }(q(e)));
}
function it(e) {
  return E(e) && B(e).position !== "fixed" ? e.offsetParent : null;
}
function nt(e) {
  const t = O(e);
  let o = it(e);
  for (; o && yo(o) && B(o).position === "static"; )
    o = it(o);
  return o && (G(o) === "html" || G(o) === "body" && B(o).position === "static" && !Re(o)) ? t : o || function(i) {
    let n = le(i);
    for (; E(n) && !We(n); ) {
      if (Re(n))
        return n;
      n = le(n);
    }
    return null;
  }(e) || t;
}
function $o(e, t, o) {
  const i = E(t), n = q(t), s = ae(e, !0, o === "fixed", t);
  let r = { scrollLeft: 0, scrollTop: 0 };
  const a = { x: 0, y: 0 };
  if (i || !i && o !== "fixed")
    if ((G(t) !== "body" || Se(n)) && (r = Pe(t)), E(t)) {
      const l = ae(t, !0);
      a.x = l.x + t.clientLeft, a.y = l.y + t.clientTop;
    } else
      n && (a.x = At(n));
  return { x: s.left + r.scrollLeft - a.x, y: s.top + r.scrollTop - a.y, width: s.width, height: s.height };
}
const _o = { getClippingRect: function(e) {
  let { element: t, boundary: o, rootBoundary: i, strategy: n } = e;
  const s = o === "clippingAncestors" ? function(d, f) {
    const c = f.get(d);
    if (c)
      return c;
    let h = $e(d).filter((w) => V(w) && G(w) !== "body"), p = null;
    const u = B(d).position === "fixed";
    let m = u ? le(d) : d;
    for (; V(m) && !We(m); ) {
      const w = B(m), g = Re(m);
      (u ? g || p : g || w.position !== "static" || !p || !["absolute", "fixed"].includes(p.position)) ? p = w : h = h.filter((v) => v !== m), m = le(m);
    }
    return f.set(d, h), h;
  }(t, this._c) : [].concat(o), r = [...s, i], a = r[0], l = r.reduce((d, f) => {
    const c = ot(t, f, n);
    return d.top = te(c.top, d.top), d.right = et(c.right, d.right), d.bottom = et(c.bottom, d.bottom), d.left = te(c.left, d.left), d;
  }, ot(t, a, n));
  return { width: l.right - l.left, height: l.bottom - l.top, x: l.left, y: l.top };
}, convertOffsetParentRelativeRectToViewportRelativeRect: function(e) {
  let { rect: t, offsetParent: o, strategy: i } = e;
  const n = E(o), s = q(o);
  if (o === s)
    return t;
  let r = { scrollLeft: 0, scrollTop: 0 }, a = { x: 1, y: 1 };
  const l = { x: 0, y: 0 };
  if ((n || !n && i !== "fixed") && ((G(o) !== "body" || Se(s)) && (r = Pe(o)), E(o))) {
    const d = ae(o);
    a = Q(o), l.x = d.x + o.clientLeft, l.y = d.y + o.clientTop;
  }
  return { width: t.width * a.x, height: t.height * a.y, x: t.x * a.x - r.scrollLeft * a.x + l.x, y: t.y * a.y - r.scrollTop * a.y + l.y };
}, isElement: V, getDimensions: function(e) {
  return E(e) ? xt(e) : e.getBoundingClientRect();
}, getOffsetParent: nt, getDocumentElement: q, getScale: Q, async getElementRects(e) {
  let { reference: t, floating: o, strategy: i } = e;
  const n = this.getOffsetParent || nt, s = this.getDimensions;
  return { reference: $o(t, await n(o), i), floating: { x: 0, y: 0, ...await s(o) } };
}, getClientRects: (e) => Array.from(e.getClientRects()), isRTL: (e) => B(e).direction === "rtl" }, xo = (e, t, o) => {
  const i = /* @__PURE__ */ new Map(), n = { platform: _o, ...o }, s = { ...n.platform, _c: i };
  return po(e, t, { ...n, platform: s });
};
function zt(e, t) {
  for (const o in t)
    Object.prototype.hasOwnProperty.call(t, o) && (typeof t[o] == "object" && e[o] ? zt(e[o], t[o]) : e[o] = t[o]);
}
const k = {
  // Disable popper components
  disabled: !1,
  // Default position offset along main axis (px)
  distance: 5,
  // Default position offset along cross axis (px)
  skidding: 0,
  // Default container where the tooltip will be appended
  container: "body",
  // Element used to compute position and size boundaries
  boundary: void 0,
  // Skip delay & CSS transitions when another popper is shown, so that the popper appear to instanly move to the new position.
  instantMove: !1,
  // Auto destroy tooltip DOM nodes (ms)
  disposeTimeout: 150,
  // Triggers on the popper itself
  popperTriggers: [],
  // Positioning strategy
  strategy: "absolute",
  // Prevent overflow
  preventOverflow: !0,
  // Flip to the opposite placement if needed
  flip: !0,
  // Shift on the cross axis to prevent the popper from overflowing
  shift: !0,
  // Overflow padding (px)
  overflowPadding: 0,
  // Arrow padding (px)
  arrowPadding: 0,
  // Compute arrow overflow (useful to hide it)
  arrowOverflow: !0,
  /**
   * By default, compute autohide on 'click'.
   */
  autoHideOnMousedown: !1,
  // Themes
  themes: {
    tooltip: {
      // Default tooltip placement relative to target element
      placement: "top",
      // Default events that trigger the tooltip
      triggers: ["hover", "focus", "touch"],
      // Close tooltip on click on tooltip target
      hideTriggers: (e) => [...e, "click"],
      // Delay (ms)
      delay: {
        show: 200,
        hide: 0
      },
      // Update popper on content resize
      handleResize: !1,
      // Enable HTML content in directive
      html: !1,
      // Displayed when tooltip content is loading
      loadingContent: "..."
    },
    dropdown: {
      // Default dropdown placement relative to target element
      placement: "bottom",
      // Default events that trigger the dropdown
      triggers: ["click"],
      // Delay (ms)
      delay: 0,
      // Update popper on content resize
      handleResize: !0,
      // Hide on clock outside
      autoHide: !0
    },
    menu: {
      $extend: "dropdown",
      triggers: ["hover", "focus"],
      popperTriggers: ["hover"],
      delay: {
        show: 0,
        hide: 400
      }
    }
  }
};
function de(e, t) {
  let o = k.themes[e] || {}, i;
  do
    i = o[t], typeof i > "u" ? o.$extend ? o = k.themes[o.$extend] || {} : (o = null, i = k[t]) : o = null;
  while (o);
  return i;
}
function bo(e) {
  const t = [e];
  let o = k.themes[e] || {};
  do
    o.$extend && !o.$resetCss ? (t.push(o.$extend), o = k.themes[o.$extend] || {}) : o = null;
  while (o);
  return t.map((i) => `v-popper--theme-${i}`);
}
function st(e) {
  const t = [e];
  let o = k.themes[e] || {};
  do
    o.$extend ? (t.push(o.$extend), o = k.themes[o.$extend] || {}) : o = null;
  while (o);
  return t;
}
let J = !1;
if (typeof window < "u") {
  J = !1;
  try {
    const e = Object.defineProperty({}, "passive", {
      get() {
        J = !0;
      }
    });
    window.addEventListener("test", null, e);
  } catch {
  }
}
let Nt = !1;
typeof window < "u" && typeof navigator < "u" && (Nt = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
const Ht = ["auto", "top", "bottom", "left", "right"].reduce((e, t) => e.concat([
  t,
  `${t}-start`,
  `${t}-end`
]), []), rt = {
  hover: "mouseenter",
  focus: "focus",
  click: "click",
  touch: "touchstart",
  pointer: "pointerdown"
}, at = {
  hover: "mouseleave",
  focus: "blur",
  click: "click",
  touch: "touchend",
  pointer: "pointerup"
};
function lt(e, t) {
  const o = e.indexOf(t);
  o !== -1 && e.splice(o, 1);
}
function Ne() {
  return new Promise((e) => requestAnimationFrame(() => {
    requestAnimationFrame(e);
  }));
}
const A = [];
let X = null;
const dt = {};
function pt(e) {
  let t = dt[e];
  return t || (t = dt[e] = []), t;
}
let Be = function() {
};
typeof window < "u" && (Be = window.Element);
function y(e) {
  return function(t) {
    return de(t.theme, e);
  };
}
const He = "__floating-vue__popper", Ve = () => xe({
  name: "VPopper",
  provide() {
    return {
      [He]: {
        parentPopper: this
      }
    };
  },
  inject: {
    [He]: { default: null }
  },
  props: {
    theme: {
      type: String,
      required: !0
    },
    targetNodes: {
      type: Function,
      required: !0
    },
    referenceNode: {
      type: Function,
      default: null
    },
    popperNode: {
      type: Function,
      required: !0
    },
    shown: {
      type: Boolean,
      default: !1
    },
    showGroup: {
      type: String,
      default: null
    },
    // eslint-disable-next-line vue/require-prop-types
    ariaId: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: y("disabled")
    },
    positioningDisabled: {
      type: Boolean,
      default: y("positioningDisabled")
    },
    placement: {
      type: String,
      default: y("placement"),
      validator: (e) => Ht.includes(e)
    },
    delay: {
      type: [String, Number, Object],
      default: y("delay")
    },
    distance: {
      type: [Number, String],
      default: y("distance")
    },
    skidding: {
      type: [Number, String],
      default: y("skidding")
    },
    triggers: {
      type: Array,
      default: y("triggers")
    },
    showTriggers: {
      type: [Array, Function],
      default: y("showTriggers")
    },
    hideTriggers: {
      type: [Array, Function],
      default: y("hideTriggers")
    },
    popperTriggers: {
      type: Array,
      default: y("popperTriggers")
    },
    popperShowTriggers: {
      type: [Array, Function],
      default: y("popperShowTriggers")
    },
    popperHideTriggers: {
      type: [Array, Function],
      default: y("popperHideTriggers")
    },
    container: {
      type: [String, Object, Be, Boolean],
      default: y("container")
    },
    boundary: {
      type: [String, Be],
      default: y("boundary")
    },
    strategy: {
      type: String,
      validator: (e) => ["absolute", "fixed"].includes(e),
      default: y("strategy")
    },
    autoHide: {
      type: [Boolean, Function],
      default: y("autoHide")
    },
    handleResize: {
      type: Boolean,
      default: y("handleResize")
    },
    instantMove: {
      type: Boolean,
      default: y("instantMove")
    },
    eagerMount: {
      type: Boolean,
      default: y("eagerMount")
    },
    popperClass: {
      type: [String, Array, Object],
      default: y("popperClass")
    },
    computeTransformOrigin: {
      type: Boolean,
      default: y("computeTransformOrigin")
    },
    /**
     * @deprecated
     */
    autoMinSize: {
      type: Boolean,
      default: y("autoMinSize")
    },
    autoSize: {
      type: [Boolean, String],
      default: y("autoSize")
    },
    /**
     * @deprecated
     */
    autoMaxSize: {
      type: Boolean,
      default: y("autoMaxSize")
    },
    autoBoundaryMaxSize: {
      type: Boolean,
      default: y("autoBoundaryMaxSize")
    },
    preventOverflow: {
      type: Boolean,
      default: y("preventOverflow")
    },
    overflowPadding: {
      type: [Number, String],
      default: y("overflowPadding")
    },
    arrowPadding: {
      type: [Number, String],
      default: y("arrowPadding")
    },
    arrowOverflow: {
      type: Boolean,
      default: y("arrowOverflow")
    },
    flip: {
      type: Boolean,
      default: y("flip")
    },
    shift: {
      type: Boolean,
      default: y("shift")
    },
    shiftCrossAxis: {
      type: Boolean,
      default: y("shiftCrossAxis")
    },
    noAutoFocus: {
      type: Boolean,
      default: y("noAutoFocus")
    },
    disposeTimeout: {
      type: Number,
      default: y("disposeTimeout")
    }
  },
  emits: {
    show: () => !0,
    hide: () => !0,
    "update:shown": (e) => !0,
    "apply-show": () => !0,
    "apply-hide": () => !0,
    "close-group": () => !0,
    "close-directive": () => !0,
    "auto-hide": () => !0,
    resize: () => !0
  },
  data() {
    return {
      isShown: !1,
      isMounted: !1,
      skipTransition: !1,
      classes: {
        showFrom: !1,
        showTo: !1,
        hideFrom: !1,
        hideTo: !0
      },
      result: {
        x: 0,
        y: 0,
        placement: "",
        strategy: this.strategy,
        arrow: {
          x: 0,
          y: 0,
          centerOffset: 0
        },
        transformOrigin: null
      },
      randomId: `popper_${[Math.random(), Date.now()].map((e) => e.toString(36).substring(2, 10)).join("_")}`,
      shownChildren: /* @__PURE__ */ new Set(),
      lastAutoHide: !0,
      pendingHide: !1,
      containsGlobalTarget: !1,
      isDisposed: !0,
      mouseDownContains: !1
    };
  },
  computed: {
    popperId() {
      return this.ariaId != null ? this.ariaId : this.randomId;
    },
    shouldMountContent() {
      return this.eagerMount || this.isMounted;
    },
    slotData() {
      return {
        popperId: this.popperId,
        isShown: this.isShown,
        shouldMountContent: this.shouldMountContent,
        skipTransition: this.skipTransition,
        autoHide: typeof this.autoHide == "function" ? this.lastAutoHide : this.autoHide,
        show: this.show,
        hide: this.hide,
        handleResize: this.handleResize,
        onResize: this.onResize,
        classes: {
          ...this.classes,
          popperClass: this.popperClass
        },
        result: this.positioningDisabled ? null : this.result,
        attrs: this.$attrs
      };
    },
    parentPopper() {
      var e;
      return (e = this[He]) == null ? void 0 : e.parentPopper;
    },
    hasPopperShowTriggerHover() {
      var e, t;
      return ((e = this.popperTriggers) == null ? void 0 : e.includes("hover")) || ((t = this.popperShowTriggers) == null ? void 0 : t.includes("hover"));
    }
  },
  watch: {
    shown: "$_autoShowHide",
    disabled(e) {
      e ? this.dispose() : this.init();
    },
    async container() {
      this.isShown && (this.$_ensureTeleport(), await this.$_computePosition());
    },
    triggers: {
      handler: "$_refreshListeners",
      deep: !0
    },
    positioningDisabled: "$_refreshListeners",
    ...[
      "placement",
      "distance",
      "skidding",
      "boundary",
      "strategy",
      "overflowPadding",
      "arrowPadding",
      "preventOverflow",
      "shift",
      "shiftCrossAxis",
      "flip"
    ].reduce((e, t) => (e[t] = "$_computePosition", e), {})
  },
  created() {
    this.autoMinSize && console.warn('[floating-vue] `autoMinSize` option is deprecated. Use `autoSize="min"` instead.'), this.autoMaxSize && console.warn("[floating-vue] `autoMaxSize` option is deprecated. Use `autoBoundaryMaxSize` instead.");
  },
  mounted() {
    this.init(), this.$_detachPopperNode();
  },
  activated() {
    this.$_autoShowHide();
  },
  deactivated() {
    this.hide();
  },
  beforeUnmount() {
    this.dispose();
  },
  methods: {
    show({ event: e = null, skipDelay: t = !1, force: o = !1 } = {}) {
      var i, n;
      (i = this.parentPopper) != null && i.lockedChild && this.parentPopper.lockedChild !== this || (this.pendingHide = !1, (o || !this.disabled) && (((n = this.parentPopper) == null ? void 0 : n.lockedChild) === this && (this.parentPopper.lockedChild = null), this.$_scheduleShow(e, t), this.$emit("show"), this.$_showFrameLocked = !0, requestAnimationFrame(() => {
        this.$_showFrameLocked = !1;
      })), this.$emit("update:shown", !0));
    },
    hide({ event: e = null, skipDelay: t = !1 } = {}) {
      var o;
      if (!this.$_hideInProgress) {
        if (this.shownChildren.size > 0) {
          this.pendingHide = !0;
          return;
        }
        if (this.hasPopperShowTriggerHover && this.$_isAimingPopper()) {
          this.parentPopper && (this.parentPopper.lockedChild = this, clearTimeout(this.parentPopper.lockedChildTimer), this.parentPopper.lockedChildTimer = setTimeout(() => {
            this.parentPopper.lockedChild === this && (this.parentPopper.lockedChild.hide({ skipDelay: t }), this.parentPopper.lockedChild = null);
          }, 1e3));
          return;
        }
        ((o = this.parentPopper) == null ? void 0 : o.lockedChild) === this && (this.parentPopper.lockedChild = null), this.pendingHide = !1, this.$_scheduleHide(e, t), this.$emit("hide"), this.$emit("update:shown", !1);
      }
    },
    init() {
      var e;
      this.isDisposed && (this.isDisposed = !1, this.isMounted = !1, this.$_events = [], this.$_preventShow = !1, this.$_referenceNode = ((e = this.referenceNode) == null ? void 0 : e.call(this)) ?? this.$el, this.$_targetNodes = this.targetNodes().filter((t) => t.nodeType === t.ELEMENT_NODE), this.$_popperNode = this.popperNode(), this.$_innerNode = this.$_popperNode.querySelector(".v-popper__inner"), this.$_arrowNode = this.$_popperNode.querySelector(".v-popper__arrow-container"), this.$_swapTargetAttrs("title", "data-original-title"), this.$_detachPopperNode(), this.triggers.length && this.$_addEventListeners(), this.shown && this.show());
    },
    dispose() {
      this.isDisposed || (this.isDisposed = !0, this.$_removeEventListeners(), this.hide({ skipDelay: !0 }), this.$_detachPopperNode(), this.isMounted = !1, this.isShown = !1, this.$_updateParentShownChildren(!1), this.$_swapTargetAttrs("data-original-title", "title"));
    },
    async onResize() {
      this.isShown && (await this.$_computePosition(), this.$emit("resize"));
    },
    async $_computePosition() {
      if (this.isDisposed || this.positioningDisabled)
        return;
      const e = {
        strategy: this.strategy,
        middleware: []
      };
      (this.distance || this.skidding) && e.middleware.push(go({
        mainAxis: this.distance,
        crossAxis: this.skidding
      }));
      const t = this.placement.startsWith("auto");
      if (t ? e.middleware.push(uo({
        alignment: this.placement.split("-")[1] ?? ""
      })) : e.placement = this.placement, this.preventOverflow && (this.shift && e.middleware.push(wo({
        padding: this.overflowPadding,
        boundary: this.boundary,
        crossAxis: this.shiftCrossAxis
      })), !t && this.flip && e.middleware.push(fo({
        padding: this.overflowPadding,
        boundary: this.boundary
      }))), e.middleware.push(co({
        element: this.$_arrowNode,
        padding: this.arrowPadding
      })), this.arrowOverflow && e.middleware.push({
        name: "arrowOverflow",
        fn: ({ placement: i, rects: n, middlewareData: s }) => {
          let r;
          const { centerOffset: a } = s.arrow;
          return i.startsWith("top") || i.startsWith("bottom") ? r = Math.abs(a) > n.reference.width / 2 : r = Math.abs(a) > n.reference.height / 2, {
            data: {
              overflow: r
            }
          };
        }
      }), this.autoMinSize || this.autoSize) {
        const i = this.autoSize ? this.autoSize : this.autoMinSize ? "min" : null;
        e.middleware.push({
          name: "autoSize",
          fn: ({ rects: n, placement: s, middlewareData: r }) => {
            var a;
            if ((a = r.autoSize) != null && a.skip)
              return {};
            let l, d;
            return s.startsWith("top") || s.startsWith("bottom") ? l = n.reference.width : d = n.reference.height, this.$_innerNode.style[i === "min" ? "minWidth" : i === "max" ? "maxWidth" : "width"] = l != null ? `${l}px` : null, this.$_innerNode.style[i === "min" ? "minHeight" : i === "max" ? "maxHeight" : "height"] = d != null ? `${d}px` : null, {
              data: {
                skip: !0
              },
              reset: {
                rects: !0
              }
            };
          }
        });
      }
      (this.autoMaxSize || this.autoBoundaryMaxSize) && (this.$_innerNode.style.maxWidth = null, this.$_innerNode.style.maxHeight = null, e.middleware.push(vo({
        boundary: this.boundary,
        padding: this.overflowPadding,
        apply: ({ availableWidth: i, availableHeight: n }) => {
          this.$_innerNode.style.maxWidth = i != null ? `${i}px` : null, this.$_innerNode.style.maxHeight = n != null ? `${n}px` : null;
        }
      })));
      const o = await xo(this.$_referenceNode, this.$_popperNode, e);
      Object.assign(this.result, {
        x: o.x,
        y: o.y,
        placement: o.placement,
        strategy: o.strategy,
        arrow: {
          ...o.middlewareData.arrow,
          ...o.middlewareData.arrowOverflow
        }
      });
    },
    $_scheduleShow(e, t = !1) {
      if (this.$_updateParentShownChildren(!0), this.$_hideInProgress = !1, clearTimeout(this.$_scheduleTimer), X && this.instantMove && X.instantMove && X !== this.parentPopper) {
        X.$_applyHide(!0), this.$_applyShow(!0);
        return;
      }
      t ? this.$_applyShow() : this.$_scheduleTimer = setTimeout(this.$_applyShow.bind(this), this.$_computeDelay("show"));
    },
    $_scheduleHide(e, t = !1) {
      if (this.shownChildren.size > 0) {
        this.pendingHide = !0;
        return;
      }
      this.$_updateParentShownChildren(!1), this.$_hideInProgress = !0, clearTimeout(this.$_scheduleTimer), this.isShown && (X = this), t ? this.$_applyHide() : this.$_scheduleTimer = setTimeout(this.$_applyHide.bind(this), this.$_computeDelay("hide"));
    },
    $_computeDelay(e) {
      const t = this.delay;
      return parseInt(t && t[e] || t || 0);
    },
    async $_applyShow(e = !1) {
      clearTimeout(this.$_disposeTimer), clearTimeout(this.$_scheduleTimer), this.skipTransition = e, !this.isShown && (this.$_ensureTeleport(), await Ne(), await this.$_computePosition(), await this.$_applyShowEffect(), this.positioningDisabled || this.$_registerEventListeners([
        ...$e(this.$_referenceNode),
        ...$e(this.$_popperNode)
      ], "scroll", () => {
        this.$_computePosition();
      }));
    },
    async $_applyShowEffect() {
      if (this.$_hideInProgress)
        return;
      if (this.computeTransformOrigin) {
        const t = this.$_referenceNode.getBoundingClientRect(), o = this.$_popperNode.querySelector(".v-popper__wrapper"), i = o.parentNode.getBoundingClientRect(), n = t.x + t.width / 2 - (i.left + o.offsetLeft), s = t.y + t.height / 2 - (i.top + o.offsetTop);
        this.result.transformOrigin = `${n}px ${s}px`;
      }
      this.isShown = !0, this.$_applyAttrsToTarget({
        "aria-describedby": this.popperId,
        "data-popper-shown": ""
      });
      const e = this.showGroup;
      if (e) {
        let t;
        for (let o = 0; o < A.length; o++)
          t = A[o], t.showGroup !== e && (t.hide(), t.$emit("close-group"));
      }
      A.push(this), document.body.classList.add("v-popper--some-open");
      for (const t of st(this.theme))
        pt(t).push(this), document.body.classList.add(`v-popper--some-open--${t}`);
      this.$emit("apply-show"), this.classes.showFrom = !0, this.classes.showTo = !1, this.classes.hideFrom = !1, this.classes.hideTo = !1, await Ne(), this.classes.showFrom = !1, this.classes.showTo = !0, this.noAutoFocus || this.$_popperNode.focus();
    },
    async $_applyHide(e = !1) {
      if (this.shownChildren.size > 0) {
        this.pendingHide = !0, this.$_hideInProgress = !1;
        return;
      }
      if (clearTimeout(this.$_scheduleTimer), !this.isShown)
        return;
      this.skipTransition = e, lt(A, this), A.length === 0 && document.body.classList.remove("v-popper--some-open");
      for (const o of st(this.theme)) {
        const i = pt(o);
        lt(i, this), i.length === 0 && document.body.classList.remove(`v-popper--some-open--${o}`);
      }
      X === this && (X = null), this.isShown = !1, this.$_applyAttrsToTarget({
        "aria-describedby": void 0,
        "data-popper-shown": void 0
      }), clearTimeout(this.$_disposeTimer);
      const t = this.disposeTimeout;
      t !== null && (this.$_disposeTimer = setTimeout(() => {
        this.$_popperNode && (this.$_detachPopperNode(), this.isMounted = !1);
      }, t)), this.$_removeEventListeners("scroll"), this.$emit("apply-hide"), this.classes.showFrom = !1, this.classes.showTo = !1, this.classes.hideFrom = !0, this.classes.hideTo = !1, await Ne(), this.classes.hideFrom = !1, this.classes.hideTo = !0;
    },
    $_autoShowHide() {
      this.shown ? this.show() : this.hide();
    },
    $_ensureTeleport() {
      if (this.isDisposed)
        return;
      let e = this.container;
      if (typeof e == "string" ? e = window.document.querySelector(e) : e === !1 && (e = this.$_targetNodes[0].parentNode), !e)
        throw new Error("No container for popover: " + this.container);
      e.appendChild(this.$_popperNode), this.isMounted = !0;
    },
    $_addEventListeners() {
      const e = (o) => {
        this.isShown && !this.$_hideInProgress || (o.usedByTooltip = !0, !this.$_preventShow && this.show({ event: o }));
      };
      this.$_registerTriggerListeners(this.$_targetNodes, rt, this.triggers, this.showTriggers, e), this.$_registerTriggerListeners([this.$_popperNode], rt, this.popperTriggers, this.popperShowTriggers, e);
      const t = (o) => {
        o.usedByTooltip || this.hide({ event: o });
      };
      this.$_registerTriggerListeners(this.$_targetNodes, at, this.triggers, this.hideTriggers, t), this.$_registerTriggerListeners([this.$_popperNode], at, this.popperTriggers, this.popperHideTriggers, t);
    },
    $_registerEventListeners(e, t, o) {
      this.$_events.push({ targetNodes: e, eventType: t, handler: o }), e.forEach((i) => i.addEventListener(t, o, J ? {
        passive: !0
      } : void 0));
    },
    $_registerTriggerListeners(e, t, o, i, n) {
      let s = o;
      i != null && (s = typeof i == "function" ? i(s) : i), s.forEach((r) => {
        const a = t[r];
        a && this.$_registerEventListeners(e, a, n);
      });
    },
    $_removeEventListeners(e) {
      const t = [];
      this.$_events.forEach((o) => {
        const { targetNodes: i, eventType: n, handler: s } = o;
        !e || e === n ? i.forEach((r) => r.removeEventListener(n, s)) : t.push(o);
      }), this.$_events = t;
    },
    $_refreshListeners() {
      this.isDisposed || (this.$_removeEventListeners(), this.$_addEventListeners());
    },
    $_handleGlobalClose(e, t = !1) {
      this.$_showFrameLocked || (this.hide({ event: e }), e.closePopover ? this.$emit("close-directive") : this.$emit("auto-hide"), t && (this.$_preventShow = !0, setTimeout(() => {
        this.$_preventShow = !1;
      }, 300)));
    },
    $_detachPopperNode() {
      this.$_popperNode.parentNode && this.$_popperNode.parentNode.removeChild(this.$_popperNode);
    },
    $_swapTargetAttrs(e, t) {
      for (const o of this.$_targetNodes) {
        const i = o.getAttribute(e);
        i && (o.removeAttribute(e), o.setAttribute(t, i));
      }
    },
    $_applyAttrsToTarget(e) {
      for (const t of this.$_targetNodes)
        for (const o in e) {
          const i = e[o];
          i == null ? t.removeAttribute(o) : t.setAttribute(o, i);
        }
    },
    $_updateParentShownChildren(e) {
      let t = this.parentPopper;
      for (; t; )
        e ? t.shownChildren.add(this.randomId) : (t.shownChildren.delete(this.randomId), t.pendingHide && t.hide()), t = t.parentPopper;
    },
    $_isAimingPopper() {
      const e = this.$_referenceNode.getBoundingClientRect();
      if (oe >= e.left && oe <= e.right && ie >= e.top && ie <= e.bottom) {
        const t = this.$_popperNode.getBoundingClientRect(), o = oe - j, i = ie - W, n = t.left + t.width / 2 - j + (t.top + t.height / 2) - W + t.width + t.height, s = j + o * n, r = W + i * n;
        return ue(j, W, s, r, t.left, t.top, t.left, t.bottom) || // Left edge
        ue(j, W, s, r, t.left, t.top, t.right, t.top) || // Top edge
        ue(j, W, s, r, t.right, t.top, t.right, t.bottom) || // Right edge
        ue(j, W, s, r, t.left, t.bottom, t.right, t.bottom);
      }
      return !1;
    }
  },
  render() {
    return this.$slots.default(this.slotData);
  }
});
if (typeof document < "u" && typeof window < "u") {
  if (Nt) {
    const e = J ? {
      passive: !0,
      capture: !0
    } : !0;
    document.addEventListener("touchstart", (t) => ct(t, !0), e), document.addEventListener("touchend", (t) => ht(t, !0), e);
  } else
    window.addEventListener("mousedown", (e) => ct(e, !1), !0), window.addEventListener("click", (e) => ht(e, !1), !0);
  window.addEventListener("resize", So);
}
function ct(e, t) {
  if (k.autoHideOnMousedown)
    Lt(e, t);
  else
    for (let o = 0; o < A.length; o++) {
      const i = A[o];
      try {
        i.mouseDownContains = i.popperNode().contains(e.target);
      } catch {
      }
    }
}
function ht(e, t) {
  k.autoHideOnMousedown || Lt(e, t);
}
function Lt(e, t) {
  const o = {};
  for (let i = A.length - 1; i >= 0; i--) {
    const n = A[i];
    try {
      const s = n.containsGlobalTarget = n.mouseDownContains || n.popperNode().contains(e.target);
      n.pendingHide = !1, requestAnimationFrame(() => {
        if (n.pendingHide = !1, !o[n.randomId] && ut(n, s, e)) {
          if (n.$_handleGlobalClose(e, t), !e.closeAllPopover && e.closePopover && s) {
            let a = n.parentPopper;
            for (; a; )
              o[a.randomId] = !0, a = a.parentPopper;
            return;
          }
          let r = n.parentPopper;
          for (; r && ut(r, r.containsGlobalTarget, e); )
            r.$_handleGlobalClose(e, t), r = r.parentPopper;
        }
      });
    } catch {
    }
  }
}
function ut(e, t, o) {
  return o.closeAllPopover || o.closePopover && t || To(e, o) && !t;
}
function To(e, t) {
  if (typeof e.autoHide == "function") {
    const o = e.autoHide(t);
    return e.lastAutoHide = o, o;
  }
  return e.autoHide;
}
function So() {
  for (let e = 0; e < A.length; e++)
    A[e].$_computePosition();
}
function Zo() {
  for (let e = 0; e < A.length; e++)
    A[e].hide();
}
let j = 0, W = 0, oe = 0, ie = 0;
typeof window < "u" && window.addEventListener("mousemove", (e) => {
  j = oe, W = ie, oe = e.clientX, ie = e.clientY;
}, J ? {
  passive: !0
} : void 0);
function ue(e, t, o, i, n, s, r, a) {
  const l = ((r - n) * (t - s) - (a - s) * (e - n)) / ((a - s) * (o - e) - (r - n) * (i - t)), d = ((o - e) * (t - s) - (i - t) * (e - n)) / ((a - s) * (o - e) - (r - n) * (i - t));
  return l >= 0 && l <= 1 && d >= 0 && d <= 1;
}
const Po = {
  extends: Ve()
}, Ce = (e, t) => {
  const o = e.__vccOpts || e;
  for (const [i, n] of t)
    o[i] = n;
  return o;
};
function Co(e, t, o, i, n, s) {
  return I(), ne("div", {
    ref: "reference",
    class: Fe(["v-popper", {
      "v-popper--shown": e.slotData.isShown
    }])
  }, [
    me(e.$slots, "default", Kt(Zt(e.slotData)))
  ], 2);
}
const Ao = /* @__PURE__ */ Ce(Po, [["render", Co]]);
function Oo() {
  var e = window.navigator.userAgent, t = e.indexOf("MSIE ");
  if (t > 0)
    return parseInt(e.substring(t + 5, e.indexOf(".", t)), 10);
  var o = e.indexOf("Trident/");
  if (o > 0) {
    var i = e.indexOf("rv:");
    return parseInt(e.substring(i + 3, e.indexOf(".", i)), 10);
  }
  var n = e.indexOf("Edge/");
  return n > 0 ? parseInt(e.substring(n + 5, e.indexOf(".", n)), 10) : -1;
}
let fe;
function Ee() {
  Ee.init || (Ee.init = !0, fe = Oo() !== -1);
}
var Ae = {
  name: "ResizeObserver",
  props: {
    emitOnMount: {
      type: Boolean,
      default: !1
    },
    ignoreWidth: {
      type: Boolean,
      default: !1
    },
    ignoreHeight: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    "notify"
  ],
  mounted() {
    Ee(), Yt(() => {
      this._w = this.$el.offsetWidth, this._h = this.$el.offsetHeight, this.emitOnMount && this.emitSize();
    });
    const e = document.createElement("object");
    this._resizeObject = e, e.setAttribute("aria-hidden", "true"), e.setAttribute("tabindex", -1), e.onload = this.addResizeHandlers, e.type = "text/html", fe && this.$el.appendChild(e), e.data = "about:blank", fe || this.$el.appendChild(e);
  },
  beforeUnmount() {
    this.removeResizeHandlers();
  },
  methods: {
    compareAndNotify() {
      (!this.ignoreWidth && this._w !== this.$el.offsetWidth || !this.ignoreHeight && this._h !== this.$el.offsetHeight) && (this._w = this.$el.offsetWidth, this._h = this.$el.offsetHeight, this.emitSize());
    },
    emitSize() {
      this.$emit("notify", {
        width: this._w,
        height: this._h
      });
    },
    addResizeHandlers() {
      this._resizeObject.contentDocument.defaultView.addEventListener("resize", this.compareAndNotify), this.compareAndNotify();
    },
    removeResizeHandlers() {
      this._resizeObject && this._resizeObject.onload && (!fe && this._resizeObject.contentDocument && this._resizeObject.contentDocument.defaultView.removeEventListener("resize", this.compareAndNotify), this.$el.removeChild(this._resizeObject), this._resizeObject.onload = null, this._resizeObject = null);
    }
  }
};
const zo = /* @__PURE__ */ Qt("data-v-b329ee4c");
qt("data-v-b329ee4c");
const No = {
  class: "resize-observer",
  tabindex: "-1"
};
Gt();
const Ho = /* @__PURE__ */ zo((e, t, o, i, n, s) => (I(), be("div", No)));
Ae.render = Ho;
Ae.__scopeId = "data-v-b329ee4c";
Ae.__file = "src/components/ResizeObserver.vue";
const qe = (e = "theme") => ({
  computed: {
    themeClass() {
      return bo(this[e]);
    }
  }
}), Lo = xe({
  name: "VPopperContent",
  components: {
    ResizeObserver: Ae
  },
  mixins: [
    qe()
  ],
  props: {
    popperId: String,
    theme: String,
    shown: Boolean,
    mounted: Boolean,
    skipTransition: Boolean,
    autoHide: Boolean,
    handleResize: Boolean,
    classes: Object,
    result: Object
  },
  emits: [
    "hide",
    "resize"
  ],
  methods: {
    toPx(e) {
      return e != null && !isNaN(e) ? `${e}px` : null;
    }
  }
}), ko = ["id", "aria-hidden", "tabindex", "data-popper-placement"], Mo = {
  ref: "inner",
  class: "v-popper__inner"
}, Ro = /* @__PURE__ */ K("div", { class: "v-popper__arrow-outer" }, null, -1), Bo = /* @__PURE__ */ K("div", { class: "v-popper__arrow-inner" }, null, -1), Eo = [
  Ro,
  Bo
];
function Do(e, t, o, i, n, s) {
  const r = se("ResizeObserver");
  return I(), ne("div", {
    id: e.popperId,
    ref: "popover",
    class: Fe(["v-popper__popper", [
      e.themeClass,
      e.classes.popperClass,
      {
        "v-popper__popper--shown": e.shown,
        "v-popper__popper--hidden": !e.shown,
        "v-popper__popper--show-from": e.classes.showFrom,
        "v-popper__popper--show-to": e.classes.showTo,
        "v-popper__popper--hide-from": e.classes.hideFrom,
        "v-popper__popper--hide-to": e.classes.hideTo,
        "v-popper__popper--skip-transition": e.skipTransition,
        "v-popper__popper--arrow-overflow": e.result && e.result.arrow.overflow,
        "v-popper__popper--no-positioning": !e.result
      }
    ]]),
    style: ze(e.result ? {
      position: e.result.strategy,
      transform: `translate3d(${Math.round(e.result.x)}px,${Math.round(e.result.y)}px,0)`
    } : void 0),
    "aria-hidden": e.shown ? "false" : "true",
    tabindex: e.autoHide ? 0 : void 0,
    "data-popper-placement": e.result ? e.result.placement : void 0,
    onKeyup: t[2] || (t[2] = Jt((a) => e.autoHide && e.$emit("hide"), ["esc"]))
  }, [
    K("div", {
      class: "v-popper__backdrop",
      onClick: t[0] || (t[0] = (a) => e.autoHide && e.$emit("hide"))
    }),
    K("div", {
      class: "v-popper__wrapper",
      style: ze(e.result ? {
        transformOrigin: e.result.transformOrigin
      } : void 0)
    }, [
      K("div", Mo, [
        e.mounted ? (I(), ne(eo, { key: 0 }, [
          K("div", null, [
            me(e.$slots, "default")
          ]),
          e.handleResize ? (I(), be(r, {
            key: 0,
            onNotify: t[1] || (t[1] = (a) => e.$emit("resize", a))
          })) : Ke("", !0)
        ], 64)) : Ke("", !0)
      ], 512),
      K("div", {
        ref: "arrow",
        class: "v-popper__arrow-container",
        style: ze(e.result ? {
          left: e.toPx(e.result.arrow.x),
          top: e.toPx(e.result.arrow.y)
        } : void 0)
      }, Eo, 4)
    ], 4)
  ], 46, ko);
}
const Ge = /* @__PURE__ */ Ce(Lo, [["render", Do]]), Ye = {
  methods: {
    show(...e) {
      return this.$refs.popper.show(...e);
    },
    hide(...e) {
      return this.$refs.popper.hide(...e);
    },
    dispose(...e) {
      return this.$refs.popper.dispose(...e);
    },
    onResize(...e) {
      return this.$refs.popper.onResize(...e);
    }
  }
};
let De = function() {
};
typeof window < "u" && (De = window.Element);
const Fo = xe({
  name: "VPopperWrapper",
  components: {
    Popper: Ao,
    PopperContent: Ge
  },
  mixins: [
    Ye,
    qe("finalTheme")
  ],
  props: {
    theme: {
      type: String,
      default: null
    },
    referenceNode: {
      type: Function,
      default: null
    },
    shown: {
      type: Boolean,
      default: !1
    },
    showGroup: {
      type: String,
      default: null
    },
    // eslint-disable-next-line vue/require-prop-types
    ariaId: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: void 0
    },
    positioningDisabled: {
      type: Boolean,
      default: void 0
    },
    placement: {
      type: String,
      default: void 0
    },
    delay: {
      type: [String, Number, Object],
      default: void 0
    },
    distance: {
      type: [Number, String],
      default: void 0
    },
    skidding: {
      type: [Number, String],
      default: void 0
    },
    triggers: {
      type: Array,
      default: void 0
    },
    showTriggers: {
      type: [Array, Function],
      default: void 0
    },
    hideTriggers: {
      type: [Array, Function],
      default: void 0
    },
    popperTriggers: {
      type: Array,
      default: void 0
    },
    popperShowTriggers: {
      type: [Array, Function],
      default: void 0
    },
    popperHideTriggers: {
      type: [Array, Function],
      default: void 0
    },
    container: {
      type: [String, Object, De, Boolean],
      default: void 0
    },
    boundary: {
      type: [String, De],
      default: void 0
    },
    strategy: {
      type: String,
      default: void 0
    },
    autoHide: {
      type: [Boolean, Function],
      default: void 0
    },
    handleResize: {
      type: Boolean,
      default: void 0
    },
    instantMove: {
      type: Boolean,
      default: void 0
    },
    eagerMount: {
      type: Boolean,
      default: void 0
    },
    popperClass: {
      type: [String, Array, Object],
      default: void 0
    },
    computeTransformOrigin: {
      type: Boolean,
      default: void 0
    },
    /**
     * @deprecated
     */
    autoMinSize: {
      type: Boolean,
      default: void 0
    },
    autoSize: {
      type: [Boolean, String],
      default: void 0
    },
    /**
     * @deprecated
     */
    autoMaxSize: {
      type: Boolean,
      default: void 0
    },
    autoBoundaryMaxSize: {
      type: Boolean,
      default: void 0
    },
    preventOverflow: {
      type: Boolean,
      default: void 0
    },
    overflowPadding: {
      type: [Number, String],
      default: void 0
    },
    arrowPadding: {
      type: [Number, String],
      default: void 0
    },
    arrowOverflow: {
      type: Boolean,
      default: void 0
    },
    flip: {
      type: Boolean,
      default: void 0
    },
    shift: {
      type: Boolean,
      default: void 0
    },
    shiftCrossAxis: {
      type: Boolean,
      default: void 0
    },
    noAutoFocus: {
      type: Boolean,
      default: void 0
    },
    disposeTimeout: {
      type: Number,
      default: void 0
    }
  },
  emits: {
    show: () => !0,
    hide: () => !0,
    "update:shown": (e) => !0,
    "apply-show": () => !0,
    "apply-hide": () => !0,
    "close-group": () => !0,
    "close-directive": () => !0,
    "auto-hide": () => !0,
    resize: () => !0
  },
  computed: {
    finalTheme() {
      return this.theme ?? this.$options.vPopperTheme;
    }
  },
  methods: {
    getTargetNodes() {
      return Array.from(this.$el.children).filter((e) => e !== this.$refs.popperContent.$el);
    }
  }
});
function Io(e, t, o, i, n, s) {
  const r = se("PopperContent"), a = se("Popper");
  return I(), be(a, wt({ ref: "popper" }, e.$props, {
    theme: e.finalTheme,
    "target-nodes": e.getTargetNodes,
    "popper-node": () => e.$refs.popperContent.$el,
    class: [
      e.themeClass
    ],
    onShow: t[0] || (t[0] = () => e.$emit("show")),
    onHide: t[1] || (t[1] = () => e.$emit("hide")),
    "onUpdate:shown": t[2] || (t[2] = (l) => e.$emit("update:shown", l)),
    onApplyShow: t[3] || (t[3] = () => e.$emit("apply-show")),
    onApplyHide: t[4] || (t[4] = () => e.$emit("apply-hide")),
    onCloseGroup: t[5] || (t[5] = () => e.$emit("close-group")),
    onCloseDirective: t[6] || (t[6] = () => e.$emit("close-directive")),
    onAutoHide: t[7] || (t[7] = () => e.$emit("auto-hide")),
    onResize: t[8] || (t[8] = () => e.$emit("resize"))
  }), {
    default: ge(({
      popperId: l,
      isShown: d,
      shouldMountContent: f,
      skipTransition: c,
      autoHide: h,
      show: p,
      hide: u,
      handleResize: m,
      onResize: w,
      classes: g,
      result: v
    }) => [
      me(e.$slots, "default", {
        shown: d,
        show: p,
        hide: u
      }),
      vt(r, {
        ref: "popperContent",
        "popper-id": l,
        theme: e.finalTheme,
        shown: d,
        mounted: f,
        "skip-transition": c,
        "auto-hide": h,
        "handle-resize": m,
        classes: g,
        result: v,
        onHide: u,
        onResize: w
      }, {
        default: ge(() => [
          me(e.$slots, "popper", {
            shown: d,
            hide: u
          })
        ]),
        _: 2
      }, 1032, ["popper-id", "theme", "shown", "mounted", "skip-transition", "auto-hide", "handle-resize", "classes", "result", "onHide", "onResize"])
    ]),
    _: 3
  }, 16, ["theme", "target-nodes", "popper-node", "class"]);
}
const Oe = /* @__PURE__ */ Ce(Fo, [["render", Io]]), kt = {
  ...Oe,
  name: "VDropdown",
  vPopperTheme: "dropdown"
}, Mt = {
  ...Oe,
  name: "VMenu",
  vPopperTheme: "menu"
}, Rt = {
  ...Oe,
  name: "VTooltip",
  vPopperTheme: "tooltip"
}, jo = xe({
  name: "VTooltipDirective",
  components: {
    Popper: Ve(),
    PopperContent: Ge
  },
  mixins: [
    Ye
  ],
  inheritAttrs: !1,
  props: {
    theme: {
      type: String,
      default: "tooltip"
    },
    html: {
      type: Boolean,
      default: (e) => de(e.theme, "html")
    },
    content: {
      type: [String, Number, Function],
      default: null
    },
    loadingContent: {
      type: String,
      default: (e) => de(e.theme, "loadingContent")
    },
    targetNodes: {
      type: Function,
      required: !0
    }
  },
  data() {
    return {
      asyncContent: null
    };
  },
  computed: {
    isContentAsync() {
      return typeof this.content == "function";
    },
    loading() {
      return this.isContentAsync && this.asyncContent == null;
    },
    finalContent() {
      return this.isContentAsync ? this.loading ? this.loadingContent : this.asyncContent : this.content;
    }
  },
  watch: {
    content: {
      handler() {
        this.fetchContent(!0);
      },
      immediate: !0
    },
    async finalContent() {
      await this.$nextTick(), this.$refs.popper.onResize();
    }
  },
  created() {
    this.$_fetchId = 0;
  },
  methods: {
    fetchContent(e) {
      if (typeof this.content == "function" && this.$_isShown && (e || !this.$_loading && this.asyncContent == null)) {
        this.asyncContent = null, this.$_loading = !0;
        const t = ++this.$_fetchId, o = this.content(this);
        o.then ? o.then((i) => this.onResult(t, i)) : this.onResult(t, o);
      }
    },
    onResult(e, t) {
      e === this.$_fetchId && (this.$_loading = !1, this.asyncContent = t);
    },
    onShow() {
      this.$_isShown = !0, this.fetchContent();
    },
    onHide() {
      this.$_isShown = !1;
    }
  }
}), Wo = ["innerHTML"], Vo = ["textContent"];
function qo(e, t, o, i, n, s) {
  const r = se("PopperContent"), a = se("Popper");
  return I(), be(a, wt({ ref: "popper" }, e.$attrs, {
    theme: e.theme,
    "target-nodes": e.targetNodes,
    "popper-node": () => e.$refs.popperContent.$el,
    onApplyShow: e.onShow,
    onApplyHide: e.onHide
  }), {
    default: ge(({
      popperId: l,
      isShown: d,
      shouldMountContent: f,
      skipTransition: c,
      autoHide: h,
      hide: p,
      handleResize: u,
      onResize: m,
      classes: w,
      result: g
    }) => [
      vt(r, {
        ref: "popperContent",
        class: Fe({
          "v-popper--tooltip-loading": e.loading
        }),
        "popper-id": l,
        theme: e.theme,
        shown: d,
        mounted: f,
        "skip-transition": c,
        "auto-hide": h,
        "handle-resize": u,
        classes: w,
        result: g,
        onHide: p,
        onResize: m
      }, {
        default: ge(() => [
          e.html ? (I(), ne("div", {
            key: 0,
            innerHTML: e.finalContent
          }, null, 8, Wo)) : (I(), ne("div", {
            key: 1,
            textContent: to(e.finalContent)
          }, null, 8, Vo))
        ]),
        _: 2
      }, 1032, ["class", "popper-id", "theme", "shown", "mounted", "skip-transition", "auto-hide", "handle-resize", "classes", "result", "onHide", "onResize"])
    ]),
    _: 1
  }, 16, ["theme", "target-nodes", "popper-node", "onApplyShow", "onApplyHide"]);
}
const Bt = /* @__PURE__ */ Ce(jo, [["render", qo]]), Et = "v-popper--has-tooltip";
function Go(e, t) {
  let o = e.placement;
  if (!o && t)
    for (const i of Ht)
      t[i] && (o = i);
  return o || (o = de(e.theme || "tooltip", "placement")), o;
}
function Dt(e, t, o) {
  let i;
  const n = typeof t;
  return n === "string" ? i = { content: t } : t && n === "object" ? i = t : i = { content: !1 }, i.placement = Go(i, o), i.targetNodes = () => [e], i.referenceNode = () => e, i;
}
let Le, pe, Yo = 0;
function Xo() {
  if (Le)
    return;
  pe = ke([]), Le = Xt({
    name: "VTooltipDirectiveApp",
    setup() {
      return {
        directives: pe
      };
    },
    render() {
      return this.directives.map((t) => Ut(Bt, {
        ...t.options,
        shown: t.shown || t.options.shown,
        key: t.id
      }));
    },
    devtools: {
      hide: !0
    }
  });
  const e = document.createElement("div");
  document.body.appendChild(e), Le.mount(e);
}
function Uo(e, t, o) {
  Xo();
  const i = ke(Dt(e, t, o)), n = ke(!1), s = {
    id: Yo++,
    options: i,
    shown: n
  };
  return pe.value.push(s), e.classList && e.classList.add(Et), e.$_popper = {
    options: i,
    item: s,
    show() {
      n.value = !0;
    },
    hide() {
      n.value = !1;
    }
  };
}
function Ft(e) {
  if (e.$_popper) {
    const t = pe.value.indexOf(e.$_popper.item);
    t !== -1 && pe.value.splice(t, 1), delete e.$_popper, delete e.$_popperOldShown, delete e.$_popperMountTarget;
  }
  e.classList && e.classList.remove(Et);
}
function ft(e, { value: t, modifiers: o }) {
  const i = Dt(e, t, o);
  if (!i.content || de(i.theme || "tooltip", "disabled"))
    Ft(e);
  else {
    let n;
    e.$_popper ? (n = e.$_popper, n.options.value = i) : n = Uo(e, t, o), typeof t.shown < "u" && t.shown !== e.$_popperOldShown && (e.$_popperOldShown = t.shown, t.shown ? n.show() : n.hide());
  }
}
const Xe = {
  beforeMount: ft,
  updated: ft,
  beforeUnmount(e) {
    Ft(e);
  }
};
function mt(e) {
  e.addEventListener("mousedown", _e), e.addEventListener("click", _e), e.addEventListener("touchstart", It, J ? {
    passive: !0
  } : !1);
}
function gt(e) {
  e.removeEventListener("mousedown", _e), e.removeEventListener("click", _e), e.removeEventListener("touchstart", It), e.removeEventListener("touchend", jt), e.removeEventListener("touchcancel", Wt);
}
function _e(e) {
  const t = e.currentTarget;
  e.closePopover = !t.$_vclosepopover_touch, e.closeAllPopover = t.$_closePopoverModifiers && !!t.$_closePopoverModifiers.all;
}
function It(e) {
  if (e.changedTouches.length === 1) {
    const t = e.currentTarget;
    t.$_vclosepopover_touch = !0;
    const o = e.changedTouches[0];
    t.$_vclosepopover_touchPoint = o, t.addEventListener("touchend", jt), t.addEventListener("touchcancel", Wt);
  }
}
function jt(e) {
  const t = e.currentTarget;
  if (t.$_vclosepopover_touch = !1, e.changedTouches.length === 1) {
    const o = e.changedTouches[0], i = t.$_vclosepopover_touchPoint;
    e.closePopover = Math.abs(o.screenY - i.screenY) < 20 && Math.abs(o.screenX - i.screenX) < 20, e.closeAllPopover = t.$_closePopoverModifiers && !!t.$_closePopoverModifiers.all;
  }
}
function Wt(e) {
  const t = e.currentTarget;
  t.$_vclosepopover_touch = !1;
}
const Ue = {
  beforeMount(e, { value: t, modifiers: o }) {
    e.$_closePopoverModifiers = o, (typeof t > "u" || t) && mt(e);
  },
  updated(e, { value: t, oldValue: o, modifiers: i }) {
    e.$_closePopoverModifiers = i, t !== o && (typeof t > "u" || t ? mt(e) : gt(e));
  },
  beforeUnmount(e) {
    gt(e);
  }
}, Qo = k, Jo = Xe, ei = Xe, ti = Ue, oi = Ue, ii = kt, ni = Mt, si = Ve, ri = Ge, ai = Ye, li = Oe, di = qe, pi = Rt, ci = Bt;
function hi(e, t = {}) {
  e.$_vTooltipInstalled || (e.$_vTooltipInstalled = !0, zt(k, t), e.directive("tooltip", Xe), e.directive("close-popper", Ue), e.component("VTooltip", Rt), e.component("VDropdown", kt), e.component("VMenu", Mt));
}
export {
  ti as B,
  hi as C,
  si as D,
  oi as E,
  ai as F,
  Ft as H,
  ri as I,
  ni as L,
  ei as M,
  Zo as N,
  Jo as O,
  li as R,
  Ht as T,
  pi as V,
  ci as W,
  at as a,
  Uo as b,
  Qo as c,
  di as j,
  ii as k,
  rt as p,
  So as t
};
